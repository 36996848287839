<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <img
    v-if="!src || src.endsWith('svg')"
    :class="className"
    :src="state.isError ? '/images/common/no-image.png' : ConvertImagePath(src, isMobile && spSize ? spSize : size)"
    :alt="alt"
    :width="width ? width : undefined"
    :height="height ? height : undefined"
    :aria-label="ariaLabel ? ariaLabel : undefined"
    :onerror="errorImage"
  />
</template>

<script setup lang="ts">
import { ConvertImagePath } from "../../utils/functions/img"

/**
 * [Nuxt Image](https://image.nuxt.com/)の仕様まとめ(2023/09/08現在)
 * - srcに空文字列を渡すとエラー
 * - widthおよびheightに小数を渡すとエラー
 * - formatの値によらずsvgはsvgに変換される
 */

type State = {
  isError: boolean
}

const state = reactive<State>({
  isError: false,
})

type Props = {
  className?: string
  src: string
  alt?: string
  width?: number
  height?: number
  size?: number
  spSize?: number
  ariaLabel?: string
}

withDefaults(defineProps<Props>(), {
  className: "",
  alt: "",
  width: 0,
  height: 0,
  size: 0.7,
  spSize: 0.7,
  ariaLabel: "",
})

const errorImage = () => {
  state.isError = true
}

// SP判定
const { isMobile } = useDevice()
</script>
